.active-cell {
  background-color:rgb(205, 255, 161);
}

.active-cell a, .active-cell a:hover {
  color: #333;
  text-decoration: none;
}

.price-changed-cell, .changed {
  border: 2px solid #000;
}

.price-error-cell, .changed {
  border: 2px solid #800;
}


.warn {
  width: 22px;
  height: 22px;
  text-align: center;
  background-color: orange;
  color: #000;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
}

.btn-vpx, .btn-sf, .btn-bik, .btn-vpx:active, .btn-vpx:hover, .btn-sf:active, .btn-sf:hover, .btn-bik:active, .btn-bik:hover {
  background-color: #999;
  color: #FFF;
}

.btn-vpx.active {
  background-color: #A33;
}

.btn-sf.active {
  background-color: #33A;
}
.btn-bik.active {
  background-color: rgb(106, 24, 108);
}