.chat-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chat-outer {
  padding: 8px;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  display: flex;
  flex-direction: row;
  margin: 0;
  border: 1px solid #000;
  overflow-wrap: break-word;
  background-color: #FFCCAA;
  /* word-break: break-all; */
}

.chat-outer-dealer {
  background-color: #CCBBCC;
}


.chat-avatar {
  width: 60px; 
  height: 60px; 
  border-radius: 60px;
}

.chat-text {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
  overflow-wrap: break-word;
}

.chat-from {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 400;
  overflow-wrap: break-word;
}

.chat-time {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 300;
  color: #666;
}
