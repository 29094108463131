.dash-widget {
  padding-top: 100%; 
  border-radius: 10px;
  border: 1px solid #666;
  background-color:#EEE;
  overflow: hidden;
}

.dash-title {
  position: absolute;
  color: #666;
  font-size: 1.0em;
  font-weight: 600;
  top: 10px;
  left: 12px;
}

.dash-subtitle {
  position: absolute;
  color: #666;
  font-size: 1.6em;
  font-weight: 500;
  top: 10px;
  right: 12px;
  text-align: right;
}

.m-dash-td {
  font-size: 1rem;
}



.m-dash-tr-underline {
  border-bottom: 1px solid #999;
}

.m-dash-tr-overline {
  border-top: 1px solid #999;
}