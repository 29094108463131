.ui-autocomplete {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.date_field {
  position: relative; 
  z-index:100 !important;
}

.ui-datepicker {
  z-index:10000 !important;
}