.dealer-sidebar {
  background-image: linear-gradient(180deg, #1AD78F 0%, #0A7E52 100%);
}

@media (min-width: 576px) {
  .dealer-sidebar  .nav-item .nav-link span {
    font-size: 1.0rem !important;
  }
}


@media (min-width: 1280px) {
  .dealer-sidebar  .nav-item .nav-link span {
    font-size: 1.2rem !important;
  }
  .dealer-sidebar .nav-item .nav-link i {
    font-size: 1.2rem !important;
    color: #000;
  }
  .dealer-sidebar.toggled {
    width: 8rem !important;
  }
}

.dealer-sidebar .nav-link{
  color: #000 !important;
  font-size: 1rem !important;
}

.dealer-sidebar .nav-item .nav-link i {
  color: #000 !important;
}

.navbar-dealer {
  background-color: #1AD78F;
}
