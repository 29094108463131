

.dealer-dashboard {
  border-radius: 1.5rem; 
  border: 0.2rem solid black; 
  text-align: center;
  overflow: hidden;
}

.reg-input-outer {
  padding: 0;
  border-radius: 1rem;
  overflow: hidden;
  border: 4px solid #000;
  margin-left: 2rem;
}

.reg-input-outer .btn {
  border-radius: 0 !important;
  min-width: 4rem;
}

.reg-input-outer .fas {
  font-size: 1.6rem;
}


.reg-input {
  padding: 0;
  font-size: 3rem; 
  font-weight: 700; 
  text-align: center;
  text-transform: uppercase;
  width: 30rem;
  border: 0;
  background-color: #F8C934;
}

.reg-input::placeholder {
  font-weight: 300;
  opacity: 0.3;
  text-transform:none;
}

.reg-input:focus {
  outline: none;
}