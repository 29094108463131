.invalid {
  border-color: brown !important;
  background-color: #FF000010 !important;
}

.cancel-text {
  color: #F66;
}

.disabled-text {
  color: #999;
}


.invalid-text {
  color: #800;
}

.hidden { display: none}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;;
}

table{
  color: #444 !important;
}


.btn-purple {
  background-color: rgb(146, 29, 255);
  color: #FFF;
}

.btn-purple:hover {
  background-color: rgb(110, 21, 193);
  color: #FFF;
}