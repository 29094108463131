#calDate {
  text-align: center;
  width: 100%;
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 1.2rem;
}

.cal-add-new {
  border: 1px solid #C5C5C5;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  margin-top: 10px;
}

.cal-add-new .cal-header {
  background-color: #E9E9E9;
  width: 100%;
  text-align: center;
  padding: 8px;
  font-weight: 700;
}

#divCalHours {
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  border-left: 1px solid #999;
  overflow: scroll;
}

#divCalHoursContent {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.tbl-cal-hour {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 3.2rem;
  background-color: #FFF;
  align-items: flex-start;
}

.cal-hour:nth-of-type(odd) { 
  background-color: #EEE;
}

.cal-hour {
  width: 100%;
  min-height: 3.2rem;
  background-color: #FFF;
}

.cal-hour:nth-of-type(odd) { 
  background-color: #EEE;
}

.cal-hour-time {
  padding: 0.4rem;
}

.cal-holiday {
  position: absolute;
  z-index: 0; 
  margin: 0.8rem 0;
  color: #999;
  font-size: 1.3rem;
}



.cal-item {
  background-color: #F99;
  margin: 1px 0 1px 4px;
  margin-right: 4px;
  height: calc(3.2rem - 2px);
  border-radius: 4px;
  border: 1px solid #999;
  padding: 0 4px;
  white-space: nowrap;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  font-size: 1.1rem;
  line-height: 1.2rem;
  z-index: 100;
}

.cal-item:hover, .cal-month-day:hover {
  background-color: #F66;
  color: #000;
  text-decoration: none;
}

.cal-td {
  border-right: 1px solid #999;
}

.cal-td:last-of-type {
  border-right: 0;
}

.cal-month-day {
  background-color: #F99;
  color: #000;
  margin: 2px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-height: 30px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  flex: 1;
}

.cal-month-date {
  color: #000;
  font-weight: 400;
  text-decoration: none;
}

.cal-month-inner {
  background-color: #EEE; 
  border-radius: 4px; 
  padding: 2px; 
  height: 100%; 
  min-height: 3rem;
}

.cal-month-inner.today {
  background-color: rgb(212, 254, 186);
  border: 1px solid #999;
}

.cal-month-date:hover {
  font-weight: 700;
  text-decoration: none;
}

.cal-item.cal-complete, .cal-month-day.cal-complete {background-color: #AFA;}
.cal-item.cal-complete:hover, .cal-month-day.cal-complete:hover {background-color: #6F6;}

.cal-item.cal-user, .cal-month-day.cal-user {background-color: #AAF;}
.cal-item.cal-user:hover, .cal-month-day.cal-user:hover {background-color: #66F;}


.horizontal-scroll-except-first-column {
  min-width: 100%;
  overflow: scroll;
}

.horizontal-scroll-except-first-column > table {
  margin-left: 8em;
}

.horizontal-scroll-except-first-column > table > * > tr > th:first-child,
.horizontal-scroll-except-first-column > table > * > tr > td:first-child {
  position: absolute;
  width: 8em;
  margin-left: -8em;
  background: #ccc;
}

.horizontal-scroll-except-first-column > table > * > tr > th,
.horizontal-scroll-except-first-column > table > * > tr > td {
  /* Without this, if a cell wraps onto two lines, the first column
   * will look bad, and may need padding. */
  white-space: nowrap;
}